import React, { useEffect } from 'react';

import DttContentContainer from 'redux/containers/districtTripTracker/DttContentContainer';
import StyledHeaderDttContainer from 'redux/containers/districtTripTracker/StyledHeaderDttContainer';

import DPApp from 'components/DPApp';
import StyledSystemAlert from 'components/Common/StyledSytemAlert/StyledSystemAlert';
import { Enum } from 'util/EnumHelper';
import { localize } from 'util/Localizer';
import { func } from 'prop-types';

function DistrictTripTracker({
    initialSetup
}) {
    const showBanner = localStorage.getItem(Enum.closedBannerCookie);
    const [ open, setOpen ] = React.useState(showBanner !== "true");

    useEffect(() => {
        initialSetup();
    }, []);

    return (
        <DPApp>
            <StyledHeaderDttContainer
                systemAlert={
                    <StyledSystemAlert
                        isAlert
                        message={localize('districtTripTracker.systemAlertMessage')}
                        severity={Enum.severityType.info}
                        show={open}
                        title={localize('districtTripTracker.systemAlertTitle')}
                        link="https://www.everdriven.com/resources/blog/revolutionizing-student-safety-everdrivens-camera-solution/"
                        linkText={localize('districtTripTracker.systemAlertLinkText')}
                        onClose={setOpen}
                    />
                }
                showAlert={open}
            />
            <DttContentContainer />
        </DPApp>
    );
}

DistrictTripTracker.defaultProps = {
    initialSetup: () => null
};

/* eslint-disable react/forbid-prop-types */
DistrictTripTracker.propTypes = {
    initialSetup: func
};
/* eslint-enable react/forbid-prop-types */

export default DistrictTripTracker;
