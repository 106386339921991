/**
 * The purpose of this file is to allow for overriding any of the values
 * within the theme config file with ones appropriate for the environment.
 *
 * While there is a good chance that this will only apply to the apiUrl, as
 * the application grows, we may want to override other variables, this allows
 * for that functionality.
 */
const Env = {
    configUrl: 'https://t23-ridemanager-ex.alcschools.cloud/api/v2/Config',
    validateUrl: 'https://t23wternbridge-externalutility.azurewebsites.net/api/v2/validate',
    applicationInsightsKey: '1a2e91b4-0a3d-4fe1-aa3a-caf275a931d7',
    auth: {
        // Web App "Application ID" in B2C Tenant - switched with MSAL upgrade to v2
        clientId: "94ff094e-0908-406b-a0eb-27652cd9d1fd",
        authoritySub: "t23wmrm",
        signInFlow: "B2C_1_New_SignIn",
        resetFlow: "B2C_1_New_PasswordReset",
        scope: "https://t23wmrm.onmicrosoft.com/ridemanager/user_impersonation"
    },
    areReportsEnabled: true,
    nodeEnv: 't23-alc'
}

export default Env;
