/* eslint max-lines: 0 */
import _ from 'lodash';
import { TransportationNeedOptions } from 'data/models/descriptions/TransportationRequestModels';
import {
    personalInformationModel,
    generalTripInformationModel,
    schoolInformationModel,
    emergencyContactInformationModel,
    schoolDto,
    populationTypeDto,
    transportationEquipmentDto
} from "data/models/tripRequestForm/TripRequestFormModel";
import { initialValues } from "util/data/dataDecorator";
import {
    addTimeZone,
    calcAge,
    dateIsValid,
    minimumDateAllowed,
    tomorrow,
    yesterday
} from "util/dateUtils";
import { Enum } from "util/EnumHelper";
import mrmFetch from 'util/fetchUtil';
import { localize } from "util/Localizer";
import { formatPhoneNumber } from 'util/validationUtil';
import {
    setClearStudentStatusModal,
    setEnabledStudentDate,
    setPopulationTypes,
    setSchedule,
    setScheduleValid,
    setSchoolDays,
    setSearchTerm,
    setSelectedStudent,
    setShowDifferentAddress,
    setTransportationNeeds,
    setTripRequestFormLoading,
    setStudentBirthDateErrorMessage,
    setStudentEndDateErrorMessage,
    setStudentStartDateErrorMessage,
    setUploadedDraftAttachments,
    updateModel,
    updateOldDraftModel,
    setNonSchoolAddressList,
    setOldDraftPopulationTypes,
    setOldDraftTransportationNeeds,
    setIsDraftSelected,
    setSelectedAccountHasNoEmail
} from "redux/actions/tripRequestFormActions";
import { searchStudent } from './districtTripTracker/dttStudentWorker';
import { onSelectSchool } from "./tripRequestFormSchoolWorker";
// eslint-disable-next-line import/no-cycle
import { isNewCorrect, onAddForm, resetValues } from "./tripRequestFormWorker";

const isModelUpdated = (
    model,
    isSameAddress,
    isSameTripInformation,
    isSameContacts,
    isSameSchool) => {
    const initialModel = {
        ...personalInformationModel,
        ...generalTripInformationModel,
        ...schoolInformationModel,
        ...emergencyContactInformationModel
    };

    initialValues(initialModel);

    return isSameAddress || isSameTripInformation || isSameContacts || isSameSchool ||
		!_.isEqual(initialModel, model);
};

const populateStudentFields = (item) => async (dispatch) => {
    dispatch(setTripRequestFormLoading(true));
    dispatch(resetValues());

    const student = await mrmFetch(
        `/api/v2/Student/${item.id}/StudentProfile`,
        'GET'
    );

    if (student.transportationEquipmentNeeds && student.transportationEquipmentNeeds.length > 0) {
        let tenProcess = 0;
        let tnList = transportationEquipmentDto;

        // eslint-disable-next-line array-callback-return
        await Promise.all(student.transportationEquipmentNeeds.map((x) => {
            switch (x) {
            case TransportationNeedOptions.monitorNeeded:
                tnList = { ...tnList, ...{ monitorNeeded: true }};
                break;
            case TransportationNeedOptions.mustRideAlone:
                tnList = { ...tnList, ...{ mustRideAlone: true }};
                break;
            case TransportationNeedOptions.boosterSeat:
                tnList = { ...tnList, ...{ boosterSeat: true }};
                break;
            case TransportationNeedOptions.safetyVest:
                tnList = { ...tnList, ...{ safetyVest: true }};
                break;
            case TransportationNeedOptions.carSeat:
                tnList = { ...tnList, ...{ carSeat: true }};
                break;
            case TransportationNeedOptions.buckleGuard:
                tnList = { ...tnList, ...{ buckleGuard: true }};
                break;
            case TransportationNeedOptions.wheelchair:
                tnList = { ...tnList, ...{ wheelchair: true }};
                break;
            default:
                break;
            }

            tenProcess += 1;
            if (tenProcess === student.transportationEquipmentNeeds.length) {
                dispatch(setTransportationNeeds(tnList));
            }
        }));
    }

    if (student.populationTypes && student.populationTypes.length > 0) {
        let ptProcess = 0;
        let ptList = populationTypeDto;

        // eslint-disable-next-line array-callback-return
        await Promise.all(student.populationTypes.map(x => {
            switch (x) {
            case Enum.populationTypes.MKV:
                ptList = { ...ptList, ...{ populationTypeMKV: true }};
                break;
            case Enum.populationTypes.ESE:
                ptList = { ...ptList, ...{ populationTypeESE: true }};
                break;
            case Enum.populationTypes.ESY:
                ptList = { ...ptList, ...{ populationTypeESY: true }};
                break;
            case Enum.populationTypes.ATP:
                ptList = { ...ptList, ...{ populationTypeATP: true }};
                break;
            case Enum.populationTypes.NCLB:
                ptList = { ...ptList, ...{ populationTypeNCLB: true }};
                break;
            case Enum.populationTypes.RegularEd:
                ptList = { ...ptList, ...{ populationTypeRegularEd: true }};
                break;
            case Enum.populationTypes.MedFragile:
                ptList = { ...ptList, ...{ populationTypeMedFragile: true }};
                break;
            case Enum.populationTypes.Magnet:
                ptList = { ...ptList, ...{ populationTypeMagnet: true }};
                break;
            case Enum.populationTypes.OutofDistrict:
                ptList = { ...ptList, ...{ populationTypeOutofDistrict: true }};
                break;
            case Enum.populationTypes.SPED:
                ptList = { ...ptList, ...{ populationTypeSPED: true }};
                break;
            case Enum.populationTypes.MultiDistrict:
                ptList = { ...ptList, ...{ populationTypeMultiDistrict: true }};
                break;
            case Enum.populationTypes.DeafEd:
                ptList = { ...ptList, ...{ populationTypeDeafEd: true }};
                break;
            case Enum.populationTypes.SpecialEvents:
                ptList = { ...ptList, ...{ populationTypeSpecialEvents: true }};
                break;
            case Enum.populationTypes.SchoolofChoice:
                ptList = { ...ptList, ...{ populationTypeSchoolofChoice: true }};
                break;
            case Enum.populationTypes.ESSA:
                ptList = { ...ptList, ...{ populationTypeESSA: true }};
                break;
            case Enum.populationTypes.PreK:
                ptList = { ...ptList, ...{ populationTypePreK: true }};
                break;
            default:
                break;
            }

            ptProcess += 1;
            if (ptProcess === student.populationTypes.length) {
                dispatch(setPopulationTypes(ptList));
            }
        }));
    }

    const dateOfBirth = !student.dateOfBirth ? null : new Date(`${student.dateOfBirth}T00:00`);
    const model = {
        ...personalInformationModel,
        ...generalTripInformationModel,
        ...schoolInformationModel,
        ...emergencyContactInformationModel,
        studentId: student.clientMemberCode,
        studentFirstName: student.firstName || '',
        studentLastName: student.lastName || '',
        studentDateOfBirth: dateOfBirth,
        requestType: student.id === '' ? Enum.requestType.new : Enum.requestType.update,
        studentAge: dateOfBirth ? calcAge(dateOfBirth) : ''
    };

    initialValues(model);
    const isDisabled = model.requestType === Enum.requestType.update && dateOfBirth !== null;

    if (student.contacts && student.contacts.length > 0) {
        model.parent1FirstName = student.contacts[0].name;
        model.parent1Relationship = student.contacts[0].relationship;
        model.parent1MobilePhone = formatPhoneNumber(student.contacts[0].mobilePhone);
        model.parent1AltPhone = formatPhoneNumber(student.contacts[0].alternatePhone);
        model.parent1EmailAddress = student.contacts[0].emailAddress ?? '';
        model.parent1AppAccess = student.contacts[0].parentAppAccessFlag;

        if (student.contacts[1]) {
            model.contact1FirstName = student.contacts[1].name;
            model.contact1Relationship = student.contacts[1].relationship;
            model.contact1MobilePhone = formatPhoneNumber(student.contacts[1].mobilePhone);
            model.contact1AltPhone = formatPhoneNumber(student.contacts[1].alternatePhone);
            model.contact1EmailAddress = student.contacts[1].emailAddress ?? '';
            model.contact1AppAccess = student.contacts[1].parentAppAccessFlag;
        }

        if (student.contacts[2]) {
            model.contact2FirstName = student.contacts[2].name;
            model.contact2Relationship = student.contacts[2].relationship;
            model.contact2MobilePhone = formatPhoneNumber(student.contacts[2].mobilePhone);
            model.contact2AltPhone = formatPhoneNumber(student.contacts[2].alternatePhone);
            model.contact2EmailAddress = student.contacts[2].emailAddress ?? '';
            model.contact2AppAccess = student.contacts[2].parentAppAccessFlag;
        }
    }

    if (student.recentAddressList && student.recentAddressList.length > 0) {
        if (student.recentAddressList.length === 1) {
            model.studentAddressLine1 = student.recentAddressList[0].AddressLine1;
            model.studentAddressLine2 = student.recentAddressList[0].AddressLine2;
            model.studentAddressCity = student.recentAddressList[0].City;
            model.studentAddressState = student.recentAddressList[0].State;
            model.studentAddressZipCode = student.recentAddressList[0].PostalCode;
            model.amPickupLocation = "Home Address";
            model.pmDropOffLocation = "Home Address";
        } else {
            dispatch(setNonSchoolAddressList(student.recentAddressList));
        }
    }

    dispatch(updateModel(model));
    dispatch(setEnabledStudentDate(isDisabled));
    dispatch(setSearchTerm(item.fullName));

    if (student.recentSchoolList && student.recentSchoolList.length === 1) {
        const studentSchool = window.Config.facilities.find(x => x.id === student.recentSchoolList[0]);
        const schooolInfo = {
            selectedSchool: studentSchool,
            id: studentSchool.id,
            name: studentSchool.name,
            address1: studentSchool.address1,
            address2: studentSchool.address2,
            city: studentSchool.city,
            zip: studentSchool.zip,
            state: studentSchool.state,
            phoneNumber: formatPhoneNumber(studentSchool.phoneNumber),
            latitude: studentSchool.lat,
            longitude: studentSchool.lng
        };

        dispatch(onSelectSchool(schooolInfo));
    } else {
        dispatch(onSelectSchool(schoolDto));
    }

    dispatch(setTripRequestFormLoading(false));
};

const populateFormWithDraft = (item) => (dispatch) => {
    dispatch(resetValues());
    dispatch(setTripRequestFormLoading(true));

    const {
        tripModel,
        populationTypes,
        showDifferentAddress,
        schedule,
        scheduleValid,
        schoolDays,
        transportationEquipmentNeeds,
        correlationId
    } = item.draftObject.Draft;

    dispatch(setShowDifferentAddress(showDifferentAddress));
    dispatch(setSchoolDays(schoolDays));
    dispatch(setScheduleValid(scheduleValid));
    dispatch(setSchedule(schedule));
    dispatch(setPopulationTypes(populationTypes));
    dispatch(setOldDraftPopulationTypes(populationTypes));
    dispatch(setTransportationNeeds(transportationEquipmentNeeds));
    dispatch(setOldDraftTransportationNeeds(transportationEquipmentNeeds));

    const dateOfBirth = !tripModel.studentDateOfBirth
        ? null
        : new Date(addTimeZone(tripModel.studentDateOfBirth));
    const startDate = !tripModel.tripStartDate ? null : new Date(tripModel.tripStartDate);
    const endDate = !tripModel.tripEndDate ? null : new Date(tripModel.tripEndDate);
    const arrivalMonday = !tripModel.schoolArrivalMonday ? null : new Date(tripModel.schoolArrivalMonday);
    const arrivalTuesday = !tripModel.schoolArrivalTuesday ? null : new Date(tripModel.schoolArrivalTuesday);
    const arrivalWednesday = !tripModel.schoolArrivalWednesday ? null : new Date(tripModel.schoolArrivalWednesday);
    const arrivalThursday = !tripModel.schoolArrivalThursday ? null : new Date(tripModel.schoolArrivalThursday);
    const arrivalFriday = !tripModel.schoolArrivalFriday ? null : new Date(tripModel.schoolArrivalFriday);
    const arrivalSaturday = !tripModel.schoolArrivalSaturday ? null : new Date(tripModel.schoolArrivalSaturday);
    const arrivalSunday = !tripModel.schoolArrivalSunday ? null : new Date(tripModel.schoolArrivalSunday);
    const pickUpMonday = !tripModel.schoolPickupMonday ? null : new Date(tripModel.schoolPickupMonday);
    const pickUpTuesday = !tripModel.schoolPickupTuesday ? null : new Date(tripModel.schoolPickupTuesday);
    const pickUpWednesday = !tripModel.schoolPickupWednesday ? null : new Date(tripModel.schoolPickupWednesday);
    const pickUpThursday = !tripModel.schoolPickupThursday ? null : new Date(tripModel.schoolPickupThursday);
    const pickUpFriday = !tripModel.schoolPickupFriday ? null : new Date(tripModel.schoolPickupFriday);
    const pickUpSaturday = !tripModel.schoolPickupSaturday ? null : new Date(tripModel.schoolPickupSaturday);
    const pickUpSunday = !tripModel.schoolPickupSunday ? null : new Date(tripModel.schoolPickupSunday);

    const model = {
        ...tripModel,
        correlationId,
        studentId: decodeURIComponent(tripModel.studentId),
        studentFirstName: decodeURIComponent(tripModel.studentFirstName),
        studentLastName: decodeURIComponent(tripModel.studentLastName),
        studentDateOfBirth: dateOfBirth,
        studentAge: dateOfBirth ? calcAge(dateOfBirth) : '',
        tripStartDate: startDate,
        tripEndDate: endDate,
        schoolArrivalMonday: arrivalMonday,
        schoolArrivalTuesday: arrivalTuesday,
        schoolArrivalWednesday: arrivalWednesday,
        schoolArrivalThursday: arrivalThursday,
        schoolArrivalFriday: arrivalFriday,
        schoolArrivalSaturday: arrivalSaturday,
        schoolArrivalSunday: arrivalSunday,
        schoolPickupMonday: pickUpMonday,
        schoolPickupTuesday: pickUpTuesday,
        schoolPickupWednesday: pickUpWednesday,
        schoolPickupThursday: pickUpThursday,
        schoolPickupFriday: pickUpFriday,
        schoolPickupSaturday: pickUpSaturday,
        schoolPickupSunday: pickUpSunday
    };

    const isDisabled = model.requestType === Enum.requestType.update && dateOfBirth !== null;

    dispatch(setTripRequestFormLoading(false));

    dispatch(setUploadedDraftAttachments([]));
    dispatch(updateModel(model));
    dispatch(updateOldDraftModel(model));
    dispatch(setEnabledStudentDate(isDisabled));
    dispatch(setSearchTerm(item.fullName));
    dispatch(setIsDraftSelected(true));
    dispatch(isNewCorrect());
};

export const onLoadStudent = (item) => (dispatch, getState) => {
    const {
        selectedStudent,
        tripModel,
        isSameAddress,
        isSameTripInformation,
        isSameContacts,
        isSameSchool
    } = getState().TripRequestFormReducers;

    if (item.id) {
        if (item.id !== selectedStudent.id) {
            dispatch(setSelectedStudent(item));
            if (item.isDraft) {
                dispatch(populateFormWithDraft(item));
            } else {
                dispatch(populateStudentFields(item));
            }
        }
    } else {
        if (!selectedStudent.id) {
            dispatch(setSearchTerm(""));
            dispatch(searchStudent('', true));
        }

        const modelIsUpdated = isModelUpdated(
            tripModel,
            isSameAddress,
            isSameTripInformation,
            isSameContacts,
            isSameSchool);

        if (modelIsUpdated) {
            dispatch(setClearStudentStatusModal(true));
        }
    }
};

export const onClearStudent = () => (dispatch) => {
    dispatch(onAddForm());
    dispatch(setClearStudentStatusModal(false));
    dispatch(setSelectedAccountHasNoEmail(false));
    dispatch(searchStudent('', true));
};

export const onUpdateStudentAge = (evt) => (dispatch) => {
    let age = '';

    if (evt) {
        if (dateIsValid(evt)) {
            age = calcAge(evt);
        }

        // eslint-disable-next-line function-call-argument-newline
        evt.setHours(0, 0, 0, 0);

        if (evt > yesterday()) {
            dispatch(setStudentBirthDateErrorMessage(localize('tripRequestForm.maxBirthDateMessage')));
        } else if (evt < minimumDateAllowed()) {
            dispatch(setStudentBirthDateErrorMessage(localize('tripRequestForm.minimunDateMessage')));
        } else if (evt && !dateIsValid(evt)) {
            dispatch(setStudentBirthDateErrorMessage(localize('tripRequestForm.invalidDateMessage')));
        } else {
            dispatch(setStudentBirthDateErrorMessage(''));
        }

        dispatch(updateModel({
            studentDateOfBirth: evt,
            studentAge: age
        }));
    } else {
        dispatch(setStudentBirthDateErrorMessage(''));
        dispatch(updateModel({
            studentDateOfBirth: null,
            studentAge: ""
        }));
    }

    dispatch(isNewCorrect());
};

export const onUpdateStartDate = (evt) => (dispatch, getState) => {
    const {
        tripModel
    } = getState().TripRequestFormReducers;

    if (evt) {
        // eslint-disable-next-line function-call-argument-newline
        evt.setHours(0, 0, 0, 0);

        if (evt < tomorrow()) {
            dispatch(setStudentStartDateErrorMessage(localize('tripRequestForm.minStartDateMessage')));
        } else if (evt && !dateIsValid(evt)) {
            dispatch(setStudentStartDateErrorMessage(localize('tripRequestForm.invalidDateMessage')));
        } else {
            dispatch(setStudentStartDateErrorMessage(''));
        }

        if (tripModel.tripEndDate && evt > tripModel.tripEndDate) {
            dispatch(setStudentEndDateErrorMessage(localize('tripRequestForm.minEndDateMessage')));
        } else {
            dispatch(setStudentEndDateErrorMessage(''));
        }
    } else {
        dispatch(setStudentStartDateErrorMessage(''));
    }

    dispatch(updateModel({
        tripStartDate: evt
    }));

    dispatch(isNewCorrect());
};

export const onUpdateEndDate = (evt) => (dispatch, getState) => {
    const {
        tripModel
    } = getState().TripRequestFormReducers;

    if (evt) {
        evt.setHours(0, 0, 0, 0);

        const dateToCompare = !tripModel.tripStartDate ? tomorrow() : tripModel.tripStartDate;

        if (evt < dateToCompare) {
            dispatch(setStudentEndDateErrorMessage(localize('tripRequestForm.minEndDateMessage')));
        } else if (evt && !dateIsValid(evt)) {
            dispatch(setStudentEndDateErrorMessage(localize('tripRequestForm.invalidDateMessage')));
        } else {
            dispatch(setStudentEndDateErrorMessage(''));
        }
    } else {
        dispatch(setStudentEndDateErrorMessage(''));
    }

    dispatch(updateModel({
        tripEndDate: evt
    }));

    dispatch(isNewCorrect());
};
