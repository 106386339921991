import _ from 'lodash';
import { fixedEncodeURIComponent } from 'util/textFormatters';
import mrmFetch from "util/fetchUtil";
import { localize } from "util/Localizer";
import { Enum } from "util/EnumHelper";
import {
    updateOldDraftModel,
    setShowSaveDraftModal,
    setDraftCanBeSaved
} from 'redux/actions/transportationRequest/transportationRequestActions';
import { getPathTabValue } from "util/updatePathTab";
import { isDateValid } from 'util/validationUtil';
import { updatePath } from "../pathWorker";
import {
    closeStyledSnackBar,
    openStyledSnackBar,
    setSnackbar
} from '../styledSnackbarWrapperWorker';

const formatDraftObject = (objectElements) => ({
    studentId: fixedEncodeURIComponent(objectElements.tripModel.studentId),
    studentFirstName: fixedEncodeURIComponent(objectElements.tripModel.studentFirstName.trim()),
    studentLastName: fixedEncodeURIComponent(objectElements.tripModel.studentLastName.trim()),
    tripModel: {
        ...objectElements.tripModel,
        studentId: fixedEncodeURIComponent(objectElements.tripModel.studentId),
        studentFirstName: fixedEncodeURIComponent(objectElements.tripModel.studentFirstName.trim()),
        studentLastName: fixedEncodeURIComponent(objectElements.tripModel.studentLastName.trim())
    },
    populationTypes: objectElements.populationTypes,
    transportationNeeds: objectElements.transportationNeeds,
    trips: objectElements.trips,
    correlationId: objectElements.correlationId,
    isNewDraft: true
});

export const onSaveDraftBody = (draftObject, nextPath) => async dispatch => {
    try {
        const body = JSON.stringify(draftObject);
        const draftResponse = await mrmFetch(`/api/v2/DraftRequest`,
            'post',
            null,
            body
        );

        if (draftResponse.length === 0) {
            dispatch(setSnackbar(
                localize('tripRequestForm.draftSuccess'),
                Enum.severityType.success
            ));
            dispatch(openStyledSnackBar());
            setTimeout(
                () => {
                    dispatch(closeStyledSnackBar());
                    dispatch(updatePath(getPathTabValue(nextPath)));
                },
                2000
            );
        } else {
            dispatch(setSnackbar(
                localize('tripRequestForm.draftError'),
                Enum.severityType.error
            ));
        }
        dispatch(openStyledSnackBar());
    } catch (error) {
        dispatch(setSnackbar(
            localize('tripRequestForm.draftError'),
            Enum.severityType.error
        ));
        dispatch(openStyledSnackBar());
        window.warn(`error in onSaveDraftBody`,
            error
        );
    }
};

export const onSaveDraft = (nextPath) => async (dispatch, getState) => {
    const {
        tripModel,
        populationTypes,
        transportationNeeds,
        trips,
        correlationId
    } = getState().TransportationRequestReducers;

    const draftObject = formatDraftObject({
        tripModel,
        populationTypes,
        transportationNeeds,
        trips,
        correlationId
    });

    dispatch(setShowSaveDraftModal(false));

    if ((tripModel.studentDateOfBirth && Number.isNaN(tripModel.studentDateOfBirth.getTime()))
        || (tripModel.tripStartDate && Number.isNaN(tripModel.tripStartDate.getTime()))
        || (tripModel.tripEndDate && Number.isNaN(tripModel.tripEndDate.getTime()))) {
        dispatch(setSnackbar(
            localize('tripRequestForm.invalidDateAlertSavingDraft'),
            Enum.severityType.error
        ));
        dispatch(openStyledSnackBar());

        return false;
    }

    dispatch(updateOldDraftModel(tripModel));
    dispatch(setDraftCanBeSaved(false));
    dispatch(onSaveDraftBody(draftObject, nextPath));

    return true;
};

export const closeSaveDraftModal = () => (dispatch) => {
    dispatch(setShowSaveDraftModal(false));
};

export const onCloseWithoutSave = (nextPath) => (dispatch) => {
    dispatch(setDraftCanBeSaved(false));
    dispatch(setShowSaveDraftModal(false));
    dispatch(updatePath(getPathTabValue(nextPath)));
};

export const onExitTRF = () => (dispatch) => {
    dispatch(setShowSaveDraftModal(true));
};

export const onValidDraftCanBeSaved = () => (dispatch, getState) => {
    const {
        tripModel,
        populationTypes,
        transportationNeeds,
        trips,
        oldDraftModel,
        oldDraftPopulationTypes,
        oldDraftTransportationNeeds,
        oldDraftTrips
    } = getState().TransportationRequestReducers;

    const draftCanBeSaved = tripModel.studentId?.trim() !== ''
        && tripModel.studentFirstName?.trim() !== ''
        && tripModel.studentLastName?.trim() !== ''
        && isDateValid(tripModel.studentDateOfBirth)
        && (!_.isEqual(tripModel, oldDraftModel) ||
        !_.isEqual(populationTypes, oldDraftPopulationTypes) ||
        !_.isEqual(transportationNeeds, oldDraftTransportationNeeds) ||
        !_.isEqual(trips, oldDraftTrips));

    dispatch(setDraftCanBeSaved(draftCanBeSaved));
};