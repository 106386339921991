/* eslint max-lines: 0 */
import _ from 'lodash';
import { searchStudent } from './districtTripTracker/dttStudentWorker';
import { Enum } from "../../util/EnumHelper";
import mrmFetch from "../../util/fetchUtil";
import { localize } from "../../util/Localizer";
import { getPathTabValue } from "../../util/updatePathTab";
import { objectEmptyValidator } from '../../util/validationUtil';
import {
    clearDraftIndicator,
    setDeleteDraftModalStatus,
    setIsDraftForSave,
    setKeyDropZone,
    setSearchTerm,
    setTripRequestFormLoading,
    updateOldDraftModel
} from "../actions/tripRequestFormActions";
import { updatePath } from "./pathWorker";
import { onAddForm } from "./tripRequestFormWorker";

import {
    closeStyledSnackBar,
    openStyledSnackBar,
    setSnackbar
} from './styledSnackbarWrapperWorker';
import { fixedEncodeURIComponent } from '../../util/textFormatters';

const errorDraft = 'tripRequestForm.draftError';

const formatDraftObject = (objectElements) => ({
    studentId: fixedEncodeURIComponent(objectElements.tripModel.studentId),
    studentFirstName: fixedEncodeURIComponent(objectElements.tripModel.studentFirstName.trim()),
    studentLastName: fixedEncodeURIComponent(objectElements.tripModel.studentLastName.trim()),
    correlationId: objectElements.tripModel.correlationId,
    tripModel: {
        ...objectElements.tripModel,
        studentId: fixedEncodeURIComponent(objectElements.tripModel.studentId),
        studentFirstName: fixedEncodeURIComponent(objectElements.tripModel.studentFirstName.trim()),
        studentLastName: fixedEncodeURIComponent(objectElements.tripModel.studentLastName.trim())
    },
    populationTypes: objectElements.populationTypes,
    transportationEquipmentNeeds: objectElements.transportationEquipmentNeeds,
    showDifferentAddress: objectElements.showDifferentAddress,
    schedule: objectElements.schedule,
    scheduleValid: objectElements.scheduleValid,
    schoolDays: objectElements.schoolDays
});

export const onSaveDraftBody = (draftObject, isBeforeLeaving) => async dispatch => {
    const body = JSON.stringify(draftObject);

    try {
        const draftResponse = await mrmFetch(`/api/v2/DraftRequest`,
            'post',
            null,
            body
        );

        if (draftResponse.length === 0) {
            dispatch(setSnackbar(
                localize('tripRequestForm.draftSuccess'),
                Enum.severityType.success
            ));
            dispatch(setIsDraftForSave(false));
            dispatch(setTripRequestFormLoading(false));
        } else {
            dispatch(setSnackbar(
                localize(errorDraft),
                Enum.severityType.error
            ));

            dispatch(setTripRequestFormLoading(false));
        }

        dispatch(onAddForm());
        dispatch(openStyledSnackBar());
        dispatch(setSearchTerm(""));

        // This is to prevent clearing a possible search on DTT when leaving TRF
        if (!isBeforeLeaving) {
            dispatch(searchStudent('', true));
        }

        setTimeout(
            () => {
                dispatch(closeStyledSnackBar());
            },
            2000
        );
    } catch (error) {
        dispatch(setTripRequestFormLoading(false));

        dispatch(setSnackbar(
            localize(errorDraft),
            Enum.severityType.error
        ));
        dispatch(openStyledSnackBar());

        window.warn(`error in onSaveDraftBody`,
            error
        );
    }
};

export const onSaveDraft = () => async (dispatch, getState) => {
    const {
        populationTypes,
        schedule,
        scheduleValid,
        schoolDays,
        showDifferentAddress,
        transportationEquipmentNeeds,
        tripModel
    } = getState().TripRequestFormReducers;

    const draftObject = formatDraftObject({
        tripModel,
        populationTypes,
        transportationEquipmentNeeds,
        schedule,
        scheduleValid,
        schoolDays,
        showDifferentAddress
    });

    if ((tripModel.studentDateOfBirth && Number.isNaN(tripModel.studentDateOfBirth.getTime()))
        || (tripModel.tripStartDate && Number.isNaN(tripModel.tripStartDate.getTime()))
        || (tripModel.tripEndDate && Number.isNaN(tripModel.tripEndDate.getTime()))) {
        dispatch(setSnackbar(
            localize('tripRequestForm.invalidDateAlertSavingDraft'),
            Enum.severityType.error
        ));
        dispatch(openStyledSnackBar());

        return false;
    }

    dispatch(setTripRequestFormLoading(true));
    dispatch(updateOldDraftModel(tripModel));
    dispatch(onSaveDraftBody(draftObject));

    return true;
};

export const onValidDraftIsSaved = () => (dispatch, getState) => {
    const {
        isDraftForSave,
        tripModel,
        oldDraftModel,
        oldDraftPopulationTypes,
        populationTypes,
        tripModels
    } = getState().TripRequestFormReducers;

    if (((isDraftForSave && objectEmptyValidator(oldDraftModel))
        || (tripModels.find(x => x.isDraftForSave && objectEmptyValidator(x.oldDraftModel))))
        || ((!objectEmptyValidator(oldDraftModel) && isDraftForSave && (!_.isEqual(tripModel, oldDraftModel)
        || !_.isEqual(populationTypes, oldDraftPopulationTypes)))
        || tripModels.find(x => !objectEmptyValidator(x.oldDraftModel) && x.isDraftForSave
        && (!_.isEqual(x.tripModel, x.oldDraftModel)
        || !_.isEqual(x.populationTypes, x.oldDraftPopulationTypes))))) {
        return true;
    }

    return false;
};

export const onSaveDraftBeforeLeave = (nextPath) => async (dispatch, getState) => {
    const {
        currentSibling,
        isDraftForSave,
        oldDraftModel,
        oldDraftPopulationTypes,
        populationTypes,
        schedule,
        scheduleValid,
        schoolDays,
        showDifferentAddress,
        transportationEquipmentNeeds,
        tripModel,
        tripModels
    } = getState().TripRequestFormReducers;

    dispatch(setTripRequestFormLoading(true));

    try {
        if ((isDraftForSave && objectEmptyValidator(oldDraftModel)
            || (!objectEmptyValidator(oldDraftModel) && isDraftForSave && (!_.isEqual(tripModel, oldDraftModel)
            || !_.isEqual(populationTypes, oldDraftPopulationTypes))))) {
            const draftObject = formatDraftObject({
                tripModel,
                populationTypes,
                transportationEquipmentNeeds,
                schedule,
                scheduleValid,
                schoolDays,
                showDifferentAddress
            });

            dispatch(onSaveDraftBody(draftObject, true));
        }

        await Promise.all(tripModels.filter(x => x.student !== currentSibling).map(async (model) => {
            if ((model.isDraftForSave && objectEmptyValidator(model.oldDraftModel)
            || (!objectEmptyValidator(model.oldDraftModel) && model.isDraftForSave
            && (!_.isEqual(model.tripModel, model.oldDraftModel)
            || !_.isEqual(model.populationTypes, model.oldDraftPopulationTypes))))) {
                const newDraftModel = formatDraftObject(model);

                dispatch(onSaveDraftBody(newDraftModel, true));
            }
        }));
    } catch (error) {
        dispatch(setSnackbar(
            localize(errorDraft),
            Enum.severityType.error
        ));

        window.warn('error in onSaveDraftBeforeLeave',
            error
        );
    }

    dispatch(setTripRequestFormLoading(false));
    dispatch(clearDraftIndicator());
    dispatch(updatePath(getPathTabValue(nextPath)));
};

export const onDeleteDraft = () => async (dispatch, getState) => {
    const { tripModel } = getState().TripRequestFormReducers;

    dispatch(setDeleteDraftModalStatus(false));
    dispatch(setTripRequestFormLoading(true));

    try {
        const resp = await mrmFetch(
            `/api/v2/DeleteDraft?query=${fixedEncodeURIComponent(tripModel.studentId)}`,
            'delete'
        );

        if (resp === true) {
            dispatch(setSnackbar(
                localize('tripRequestForm.deleteDraftSuccess'),
                Enum.severityType.success
            ));
            dispatch(setKeyDropZone(Math.random().toString()));
            dispatch(onAddForm());
        } else {
            dispatch(setSnackbar(
                localize('tripRequestForm.deleteDraftError'),
                Enum.severityType.error
            ));
        }

        dispatch(setTripRequestFormLoading(false));
        dispatch(searchStudent('', true));
        dispatch(openStyledSnackBar());
        setTimeout(
            () => {
                dispatch(closeStyledSnackBar());
            },
            2000
        );
    } catch (error) {
        dispatch(setDeleteDraftModalStatus(false));
        dispatch(setTripRequestFormLoading(false));
        dispatch(setSnackbar(
            localize('tripRequestForm.deleteDraftError'),
            Enum.severityType.error
        ));
        dispatch(openStyledSnackBar());
        window.warn(`error in onDeleteDraft`,
            error
        );
    }
};
