import React from 'react';
import MuiAlert from '@mui/material/Alert';
import MuiAlertTitle from '@mui/material/AlertTitle';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import {
    bool,
    string,
    oneOfType,
    array,
    func
} from 'prop-types';
import { styled } from '@mui/material/styles';
import { Enum, museoSansFont500, museoSansFont700 } from 'util/EnumHelper';
import breakpointsNames from 'styles/ResponsiveConstants';
import commonUseStyles from 'styles/CommonStyles';

const SystemAlert = styled(MuiAlert)(({ theme }) => ({
    '& .MuiAlert-message': {
        marginTop: 5,
        fontSize: 14
    },
    backgroundColor: `${theme.palette.everDrivenLightBlue} !important`,
    ...commonUseStyles.defaultResponsive,
    minHeight: 130,
    margin: '8px 53px',
    [theme.breakpoints.down(breakpointsNames.md)]: {
        margin: '8px 0px'
    }
}));

const Title = styled(MuiAlertTitle)(({ theme }) => ({
    color: `${theme.palette.white} !important`,
    fontSize: 24,
    fontFamily: museoSansFont700,
    lineHeight: 'normal',
    display: 'flex',
    alignItems: 'center'
}));

const CloseButton = styled(IconButton)(({ theme }) => ({
    color: theme.palette.white,
    marginLeft: 'auto',
    fontSize: 18
}));

const OverflowText = styled('span')(({ theme }) => ({
    color: `${theme.palette.white} !important`,
    paddingRight: 15,
    fontFamily: museoSansFont500,
    fontSize: 16,
    lineHeight: '24px'
}));

const StyledLink = styled('a')(({theme}) => ({
    color: `${theme.palette.white} !important`
}));

function StyledSystemAlert({
    isAlert,
    message,
    show,
    title,
    link,
    linkText,
    type,
    onClose
}) {
    const handleCloseBanner = () => {
        onClose(false);
        localStorage.setItem(Enum.closedBannerCookie, "true");
    };

    if (!isAlert) {
        return null;
    }

    return (
        <Collapse in={show}>
            <SystemAlert
                elevation={6}
                severity={ type === Enum.severityType.warning
                    ? Enum.severityType.error
                    : Enum.severityType.info
                }
                icon={false}
                variant="filled"
            >
                <Title>
                    <div>
                        {title}
                    </div>
                    <CloseButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        data-testid="closeTemporalBanner"
                        onClick={handleCloseBanner}
                    >
                        <CloseIcon fontSize="inherit" />
                    </CloseButton>
                </Title>
                <OverflowText>
                    {message} {(link && linkText) && <StyledLink href={link} target="_blank">{linkText}</StyledLink>}
                </OverflowText>
            </SystemAlert>
        </Collapse>
    );
}

StyledSystemAlert.defaultProps = {
    isAlert: false,
    message: '',
    show: true,
    title: '',
    link: '',
    linkText: '',
    type: Enum.severityType.warning,
    onClose: () => null
};

StyledSystemAlert.propTypes = {
    isAlert: bool,
    message: oneOfType([
        string,
        array
    ]),
    show: bool,
    title: string,
    link: string,
    linkText: string,
    type: string,
    onClose: func
};

export default StyledSystemAlert;
